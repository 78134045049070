/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef, Ref, Fragment, Suspense } from 'preact/compat';
import {
  faBars,
  faQuestion,
  faTimes,
  faEllipsisV,
  faScrewdriverWrench,
  faStarAndCrescent,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, Link } from 'react-router-dom';
import { isAdminScreen } from 'utils/task';
import { parseUserObject } from 'utils/user';
import AvatarIcon from 'assets/images/Avatar.png';
import LogoutIcon from 'assets/images/logout.png';
import MoreIcon from 'assets/images/more.png';
import FeedbackIcon from 'assets/images/feedback.png';
import SettingsIcon from 'assets/images/settings.png';
import { Tooltip } from 'react-tooltip';
import {
  loginUserInApp,
  getUserWorkspace,
  getUserWorkspaces,
  logOutUser,
  isAdmin,
  isSuperAdmin,
  updateWorkspaceName,
  getUserEmail,
  uploadWorkspaceImage as uploadWorkspaceImageAPI,
  leaveWorkspace as leaveWorkspaceAPI,
  deleteWorkspace as deleteWorkspaceAPI,
} from 'services/AuthService';
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { Strings } from 'resources';
import TopbarInput from 'components/TopbarInput';
import ProgressBar from 'components/UI/ProgressBar';
import ImageUploading from 'react-images-uploading';
import { useAlert } from 'react-alert';
import { log } from 'utils';
import { lazyWithRetry } from 'utils/helpers';
import useWindowDimensions from 'utils/WindowDimensions';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  currentRouteState,
  goToUrlState,
  hideLeftSidebarDesktopState,
  hideLeftSidebarState,
  hideWorkspaceSwitchState,
  navigationDataSelector,
  showCreateWorkspaceModalState,
  showFeedbackModalState,
  showLeaderboardModalState,
  showNotificationPopupState,
  showSearchModalState,
  showUserProfileModalState,
  showUpgradeModalState,
  showPricingModalState,
} from 'recoil/NavigationState/index';
import Logo from 'assets/images/Logo-Icon.png';
import { updateWorkspace } from 'recoil/AccountState/update';
import { accountDataSelector, screenSaverState, subscriptionState } from 'recoil/AccountState';
import { setItem } from 'utils/storage';
import SearchIcon from 'assets/images/search.png';
import SearchCrownIcon from 'assets/images/payment/search-crown.svg';
import SuperAdminIcon from 'assets/images/super-admin-icon.svg';

import { initializeTriggers } from 'triggers';
import { playModalCloseSound, playModalOpenSound } from 'triggers/utils/sound';
import { getLayoutThemeUrl } from 'utils/theme';

const LevelUpModal = lazyWithRetry(() => import('modals/LevelUpModal'));
const ThemesPopup = lazyWithRetry(() => import('modals/ThemePopup'));
const ThemeCardModal = lazyWithRetry(() => import('modals/ThemeCardModal'));

const ConfirmationPopup = lazyWithRetry(() => import('modals/ConfirmationPopup'));

export default function TopBar(props: TopBarPropsType) {
  const { currentRoute } = props;
  const navigationData: NavigationDataType = useRecoilValue(navigationDataSelector);
  const accountData = useRecoilValue(accountDataSelector);
  const subscriptionDetail = useRecoilValue(subscriptionState);
  const setShowUserProfileModal = useSetRecoilState(showUserProfileModalState);
  const [images, setImages] = useState([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const [showNotification, setShowNotification] = useRecoilState(showNotificationPopupState);
  const [isLoading, setIsLoading] = useState(false);
  const [userWorkspace, setUserWorkspace] = useState(getUserWorkspace());
  const [userWorkspaces, setUserWorkspaces] = useState(getUserWorkspaces());
  const [activeTheme, setActiveTheme] = useState<ThemeInstanceInterface | null>(null);
  const [renameWorkspaceId, setRenameWorkspaceId] = useState<string | null>(null);
  const [renameWorkspaceValue, setRenameWorkspaceValue] = useState('');

  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [showDeleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);

  // navigation recoil states
  const setCurrentRoute = useSetRecoilState(currentRouteState);
  const setHideLeftSidebar = useSetRecoilState(hideLeftSidebarState);
  const setHideLeftSidebarDesktop = useSetRecoilState(hideLeftSidebarDesktopState);
  const setHideWorkspaceSwitch = useSetRecoilState(hideWorkspaceSwitchState);
  const setShowCreateWorkspaceModal = useSetRecoilState(showCreateWorkspaceModalState);
  const setSearchModalState = useSetRecoilState(showSearchModalState);
  const setFeedbackModalStatus = useSetRecoilState(showFeedbackModalState);
  const setScreenSaverState = useSetRecoilState(screenSaverState);
  const setShowUpgradeModal = useSetRecoilState(showUpgradeModalState);
  const setShowPricingModal = useSetRecoilState(showPricingModalState);

  const premiumStatus = accountData.account?.workspace?.premiumStatus;

  const [showThemesLevelUpModal, setThemesLevelUpModalStatus] = useState(
    navigationData.showThemesLevelUpModal,
  );

  const [showThemesPopupModal, setThemesPopupModalStatus] = useState(
    navigationData.showThemesPopupModal,
  );
  const [showThemeCardModal, setThemeCardModalStatus] = useState(navigationData.showThemeCardModal);

  const ref: Ref<HTMLInputElement> | null = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const setGoToUrl = useSetRecoilState(goToUrlState);

  const { width } = useWindowDimensions();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        isOpen && resetRenameWorkspace();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isOpen]);

  useEffect(() => {
    if (accountData.account && accountData.account.workspace) {
      setUserWorkspace(accountData.account.workspace);
      setUserWorkspaces(accountData.account.workspaces ? accountData.account.workspaces : []);
    }
  }, [accountData]);

  useEffect(() => {
    setThemesLevelUpModalStatus(navigationData.showThemesLevelUpModal);
    if (navigationData.showThemesLevelUpModal) {
      initializeTriggers(true);
    }
    const val =
      accountData &&
      accountData.purchasedThemes.find((element) => {
        return element.equipped === true;
      });
    if (val) setActiveTheme(val);
  }, [navigationData.showThemesLevelUpModal, accountData.purchasedThemes]);

  useEffect(() => {
    setThemesPopupModalStatus(navigationData.showThemesPopupModal);
  }, [navigationData.showThemesPopupModal]);

  useEffect(() => {
    setThemeCardModalStatus(navigationData.showThemeCardModal);
  }, [navigationData.showThemeCardModal]);

  useEffect(() => {
    log('navigate object', navigate);
    if (typeof navigationData.goToUrl !== 'undefined') {
      const url = navigationData.goToUrl;
      setGoToUrl(undefined);
      log('SET NEW URL');
      setTimeout(() => {
        if (url === '/') navigate(url, { replace: true });
        else navigate(url);
      }, 20);
    }
  }, [navigationData.goToUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentRoute(currentRoute);
  }, [currentRoute]);

  useEffect(() => {
    if (navigationData.hideLeftSidebarDesktop) {
      playModalCloseSound();
    } else {
      playModalOpenSound();
    }
  }, [navigationData.hideLeftSidebarDesktop]);

  const toggleLeftSidebar = (status?: boolean) => {
    setHideLeftSidebar(status ? status : !navigationData.hideLeftSidebar);
  };

  const toggleLeftSidebarDesktop = () => {
    setItem('hideLeftSidebarDesktop', (!navigationData.hideLeftSidebarDesktop).toString());
    setHideLeftSidebarDesktop(!navigationData.hideLeftSidebarDesktop);
  };

  const isThisProjectsScreen = () => {
    const { currentRoute } = navigationData;

    return currentRoute ? isAdminScreen(currentRoute) : false;
  };

  const toggleNotificationList = () => {
    setShowNotification(!showNotification);
  };

  const askForPermission = () => {
    toggleNotificationList();
  };

  const renderNotificationBadge = () => {
    if (accountData.notifications.unreadCounts > 0) {
      return (
        <span
          id='notif-badge'
          className='notif-badge'
          onClick={() => toggleNotificationList()}
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-my-pending-notifications`}
          data-tooltip-content={`You have ${accountData.notifications.unreadCounts} unread notifications`}
        >
          <p id='notif-count'>{accountData.notifications.unreadCounts}</p>
        </span>
      );
    }
    return;
  };

  const toggleWorkspaceSwitch = (status?: boolean) => {
    setItem(
      'hideWorkspaceSwitch',
      status ? status.toString() : (!navigationData.hideWorkspaceSwitch).toString(),
    );
    setHideWorkspaceSwitch(status ? status : !navigationData.hideWorkspaceSwitch);
  };

  const setNewWorkspaceName = async (id: string, name: string) => {
    if (typeof userWorkspace === 'boolean') return;
    resetRenameWorkspace();
    const initialWorkspace = userWorkspace;
    const tempWorkspace = userWorkspace;

    tempWorkspace.id = id;
    tempWorkspace.name = name;
    // Update name in redux
    updateWorkspace(tempWorkspace);

    // Call api to update in backend
    const { data, success } = await updateWorkspaceName(id, name);

    if (!success) {
      updateWorkspace(initialWorkspace);
    } else {
      // Update name in redux
      updateWorkspace(data);
    }
  };

  /**
   * render workspace name as dropdown if multiple workspaces exist and text if only one workspace in user's profile
   */
  const renderWorkspaceName = () => {
    if (renameWorkspaceId && typeof userWorkspace !== 'boolean') {
      return (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setNewWorkspaceName(userWorkspace.id, renameWorkspaceValue);
          }}
        >
          <span className='rename-folder-input-con'>
            <input
              ref={ref}
              className='rename-input'
              value={renameWorkspaceValue}
              onChange={(e) =>
                setRenameWorkspaceValue(e.target ? (e.target as HTMLInputElement).value : '')
              }
              autoComplete='off'
              data-lpignore='true'
              data-form-type='other'
              placeholder='Workspace name'
            />
          </span>
        </form>
      );
    }

    return (
      <div
        id='user-workspace-option'
        className='user-workspace user-workspace-option'
        title={
          userWorkspace && typeof userWorkspace !== 'boolean' && userWorkspace.name
            ? userWorkspace.name
            : ''
        }
        onClick={() => toggleWorkspaceSwitch()}
      >
        {userWorkspace && typeof userWorkspace !== 'boolean' && userWorkspace.name
          ? userWorkspace.name
          : ''}
      </div>
    );
  };

  const openProfileModal = () => {
    setShowUserProfileModal(true);
    playModalOpenSound();
  };

  const getProfileImage = () => {
    if (accountData.account && accountData.account.profileImage) {
      return accountData.account.profileImage;
    }

    return AvatarIcon;
  };

  const searchClicked = () => {
    if (premiumStatus === 'FREE' || premiumStatus === 'PENDING_PAYMENT') {
      setShowUpgradeModal('SEARCH');
    } else {
      setSearchModalState(true);
    }
  };

  const showFeedbackModal = () => {
    setFeedbackModalStatus(true);
  };

  const handleClickLeave = async () => {
    if (typeof userWorkspace === 'boolean') return;
    setIsLoading(true);
    const { data, success } = await leaveWorkspaceAPI(userWorkspace.id);
    setIsLoading(false);
    setConfirmationPopup(false);
    if (success) {
      log(data);
      const user = parseUserObject(data);
      loginUserInApp(user);

      navigate('/');
    }
  };

  const handleClickDelete = async (text: string) => {
    if (typeof userWorkspace === 'boolean') return;
    if (text.toLowerCase() !== 'delete') {
      alert.error(Strings.type_delete_for_confirmation);
      return;
    }
    setIsLoading(true);
    await deleteWorkspaceAPI(userWorkspace.id);
    setIsLoading(false);
    setDeleteConfirmationPopup(false);
  };

  const renameWorkspace = () => {
    if (typeof userWorkspace === 'boolean') return;
    setRenameWorkspaceId(userWorkspace.id);
    setRenameWorkspaceValue(userWorkspace.name ? userWorkspace.name : '');
    setIsOpen(true);

    document.addEventListener('keydown', keyDown, false);
  };

  const keyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetRenameWorkspace();
    }
  };

  const resetRenameWorkspace = () => {
    setRenameWorkspaceId(null);
    setRenameWorkspaceValue('');
    setIsOpen(false);

    document.removeEventListener('keydown', keyDown, false);
  };

  const isWorkspaceOwner = () => {
    if (typeof userWorkspace === 'boolean') return;
    const userEmail = getUserEmail();
    if (userEmail === userWorkspace.owner) {
      return true;
    }
    return false;
  };

  const onChangeWorkspaceImage = (imageList, _index) => {
    setImages(imageList);
    uploadWorkspaceImage(imageList);
  };

  const uploadWorkspaceImage = async (imageList) => {
    const formData = new FormData();

    // Update the formData object
    formData.append('file', imageList[0].file);
    const { data, success } = await uploadWorkspaceImageAPI(formData);

    if (!success) {
      alert.error(Strings.image_upload_failed);
      setImages([]);
    } else {
      alert.success('Image Uploaded Successfully!');
      updateWorkspace(data);
    }
  };

  const renderImageUpload = () => {
    return (
      <div className='image-uploader'>
        {/* @ts-ignore*/}
        <ImageUploading value={images} onChange={onChangeWorkspaceImage} dataURLKey='data_url'>
          {/* @ts-ignore*/}
          {({ onImageUpload, isDragging, dragProps }) => (
            // write your building UI
            <div className='upload__image-wrapper'>
              <button
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}
              >
                {Strings.update_workspace_image}
              </button>
            </div>
          )}
        </ImageUploading>
      </div>
    );
  };

  const renderWorkspaceNameUpdateMenu = () => {
    if (!isAdmin() && !isWorkspaceOwner()) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem id='rename-workspace-menu-item' onClick={() => renameWorkspace()}>
        {Strings.rename_workspace}
      </MenuItem>
    );
  };

  const renderWorkspaceDeleteMenu = () => {
    let hide = false;
    if (!isWorkspaceOwner() || !userWorkspaces) {
      hide = true;
    } else if (userWorkspaces && userWorkspaces.length <= 1) {
      hide = true;
    }

    if (hide) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem
        id='delete-workspace-menu-item'
        className='remove-item'
        onClick={() => setDeleteConfirmationPopup(true)}
      >
        {Strings.delete_workspace}
      </MenuItem>
    );
  };

  const showWorspaceCreationModal = () => {
    setShowCreateWorkspaceModal(true);
  };

  const renderCreateWorkspaceMenu = () => {
    return (
      <MenuItem id='create-workspace-menu-item' onClick={() => showWorspaceCreationModal()}>
        {Strings.create_workspace}
      </MenuItem>
    );
  };

  const renderWorkspaceImageUpdateMenu = () => {
    if (!isAdmin() && !isWorkspaceOwner()) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem id='update-workspace-image-menu-item' key={1}>
        {renderImageUpload()}
      </MenuItem>
    );
  };

  const renderLeaveWorkspaceMenu = () => {
    if (!userWorkspaces) return null;

    if (userWorkspaces.length <= 1 || isWorkspaceOwner()) {
      return <MenuItem className='hidden'>hidden</MenuItem>;
    }

    return (
      <MenuItem id='leave-workspace-menu-item' onClick={(_e) => setConfirmationPopup(true)} key={2}>
        {Strings.leave_workspace}
      </MenuItem>
    );
  };

  const renderWorkspaceMenu = () => {
    return (
      <Menu
        className='workspace-menu'
        menuButton={
          <MenuButton id='workspace-menu-btn' className='workspace-menu-btn'>
            <FontAwesomeIcon
              id='workspace-menu-icon'
              className='workspace-menu-icon icon'
              icon={faEllipsisV}
              size='sm'
            />
          </MenuButton>
        }
        direction='bottom'
        align='end'
      >
        {renderCreateWorkspaceMenu()}
        {renderLeaveWorkspaceMenu()}
        {renderWorkspaceNameUpdateMenu()}
        {renderWorkspaceImageUpdateMenu()}
        {renderWorkspaceDeleteMenu()}
      </Menu>
    );
  };

  const renderIndicator = () => {
    if (premiumStatus === 'GRANDFATHERED') {
      return null;
    }

    return (
      <p className='workspace-indicator' onClick={() => setShowPricingModal(true)}>
        {premiumStatus === 'PREMIUM' ? Strings.elite : Strings.pleb}
      </p>
    );
  };

  const renderLeftContainer = () => {
    return (
      <div id='top-bar-left-side' className='left-side'>
        <div id='left-bar-toggle' className='left-bar-toggle' onClick={() => toggleLeftSidebar()}>
          {navigationData.hideLeftSidebar ? (
            <FontAwesomeIcon id='bars-icon' icon={faBars} size='lg' />
          ) : (
            <FontAwesomeIcon id='times-icon' icon={faTimes} size='lg' />
          )}
        </div>
        <div
          id='left-bar-toggle-desktop'
          className='left-bar-toggle desktop'
          onClick={() => toggleLeftSidebarDesktop()}
        >
          {navigationData.hideLeftSidebarDesktop ? (
            <FontAwesomeIcon id='bars-icon' icon={faBars} size='lg' />
          ) : (
            <FontAwesomeIcon id='times-icon' icon={faTimes} size='lg' />
          )}
        </div>
        <div id='site-logo-con' className='site-logo'>
          {/* @ts-ignore*/}
          <Link to='/'>
            {/* @ts-ignore */}
            <img src={Logo} alt='Logo' />
          </Link>
        </div>
        {renderWorkspaceName()}
        {renderIndicator()}
        {renderWorkspaceMenu()}
      </div>
    );
  };

  const renderSuperAdminMenuLink = () => {
    if (!isSuperAdmin())
      return (
        <MenuItem key={2} id='super-admin-hidden-menu-item' className='hidden'>
          hide
        </MenuItem>
      );

    return [
      <MenuItem id='super-admin-menu-item' onClick={() => navigate('/super-admin/themes')} key={2}>
        <img id='super-admin-menu-img' src={SuperAdminIcon} alt='Super Admin' />

        {Strings.super_admin}
      </MenuItem>,
      <MenuDivider key={12} />,
    ];
  };

  const renderUserTutorialMenuLink = () => {
    if (width <= 800) {
      return (
        <MenuItem key={4} id='user-tutorial-hidden-menu-item' className='hidden'>
          hide
        </MenuItem>
      );
    }

    return [
      <MenuItem
        id='user-tutorial-menu-item'
        className='user-tutorial-menu-item'
        onClick={showUserTutorial}
        key={4}
      >
        <FontAwesomeIcon id='question-icon' icon={faQuestion} size='sm' />
        {Strings.user_tutorial}
      </MenuItem>,
      <MenuDivider key={14} />,
    ];
  };

  /**
   * show user tutorial
   */

  const showUserTutorial = () => {
    navigate('/user-tutorial');
    toggleWorkspaceSwitch(true);
  };

  const enterScreenSaverMode = () => {
    setScreenSaverState(true);
  };

  const renderSettings = () => {
    return (
      <span id='more-icon-con' className='top-icon more-icon'>
        <Menu
          id='more-menu'
          menuButton={
            <MenuButton>
              <img id='more-menu-img' src={MoreIcon} alt='More' />
            </MenuButton>
          }
          align='end'
        >
          <MenuItem key={1} id='screensaver-menu-item' onClick={enterScreenSaverMode}>
            <FontAwesomeIcon icon={faStarAndCrescent} />
            {Strings.screen_saver}
          </MenuItem>
          <MenuDivider key={11} />
          <MenuItem key={2} id='feedback-menu-item' onClick={showFeedbackModal}>
            <img src={FeedbackIcon} alt='Submit feedback' />
            {Strings.submit_feedback}
          </MenuItem>
          <MenuDivider key={12} />

          {renderSuperAdminMenuLink()}
          <MenuItem key={3} id='settings-menu-item' onClick={() => navigate('/settings')}>
            <img src={SettingsIcon} alt='Settings' />
            {Strings.settings}
          </MenuItem>
          <MenuDivider key={13} />
          {renderUserTutorialMenuLink()}
          <MenuItem key={5} id='logout-menu-item' onClick={logOutUser}>
            <img src={LogoutIcon} alt='Logout' />
            {Strings.logout}
          </MenuItem>
        </Menu>
      </span>
    );
  };

  const showAdmin = () => {
    navigate('/admin/portfolio');
    setHideLeftSidebar(true);
  };

  const renderLeaderboardOption = () => {
    return (
      <Fragment>
        <span
          id='leaderboard-icon-con'
          className='top-icon leaderboard-icon'
          onClick={() => openLeaderboardModal()}
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-leaderboard`}
          data-tooltip-content={`Leaderboard`}
        />
        {/* @ts-ignore*/}
        <Tooltip className='tool-tip' id={`tool-tip-leaderboard`} />
      </Fragment>
    );
  };

  const renderAdminOption = () => {
    if (!isAdmin()) return null;
    return (
      <Fragment>
        <span
          id='admin-icon-con'
          className='top-icon admin-icon'
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-admin`}
          data-tooltip-content={Strings.workspace_administration}
        >
          <button id='admin-btn' className='admin-btn' onClick={showAdmin}>
            <FontAwesomeIcon icon={faScrewdriverWrench} />
          </button>
        </span>
        {/* @ts-ignore*/}
        <Tooltip className='tool-tip' id={`tool-tip-admin`} />
      </Fragment>
    );
  };

  const setLeaderboardModalStatus = useSetRecoilState(showLeaderboardModalState);
  const openLeaderboardModal = () => {
    navigate('/leaderboard/individuals');
    setLeaderboardModalStatus(true);
    toggleLeftSidebar(true);
  };

  const renderLevelProgressBar = () => {
    return activeTheme ? (
      <ProgressBar
        progress={activeTheme.progress}
        themeName={activeTheme.theme ? activeTheme.theme.name : ''}
        level={activeTheme.level}
      />
    ) : null;
  };

  const renderNotificationOption = () => {
    return (
      <div id='notification-section-con' className='notification-section'>
        <div id='notification-icon-sec' className='iconSection'>
          <span
            id='notif-icon-con'
            className={`top-icon notif-icon ${isThisProjectsScreen() ? 'selected' : ''}`}
            onClick={() => askForPermission()}
            data-place='bottom'
            data-class='tool-tip'
            data-background-color='#3c3c5b'
            data-tooltip-id={`tool-tip-my-notifications`}
            data-tooltip-content={`View my notifications`}
          />
          {/* @ts-ignore*/}
          <Tooltip className='tool-tip' id={`tool-tip-my-notifications`} />
          {renderNotificationBadge()}
          {/* @ts-ignore*/}
          <Tooltip className='tool-tip' id={`tool-tip-my-pending-notifications`} />
        </div>
      </div>
    );
  };

  const renderSearchOption = () => {
    return (
      <span
        id='search-icon'
        className='top-icon search-icon'
        data-place='bottom'
        data-class='tool-tip'
        data-background-color='#3c3c5b'
        data-tooltip-id={`tool-tip-search`}
        data-tip={`Search`}
      >
        <button id='search-btn' className='search-btn' onClick={searchClicked}>
          {premiumStatus === 'FREE' || premiumStatus === 'PENDING_PAYMENT' ? (
            <img id='search-img' src={SearchCrownIcon} alt='search' className='search-crown' />
          ) : (
            <img id='search-img' src={SearchIcon} alt='search' />
          )}
        </button>
      </span>
    );
  };

  return (
    <div
      id='top-bar-container'
      className={`top-bar-container ${navigationData.hideWorkspaceSwitch ? '' : 'workspace-switch'}
      ${!navigationData.hideLeftSidebar ? 'left-menu-open' : ''}`}
    >
      {renderLeftContainer()}
      <div
        id='task-input-container'
        className={`task-input-container ${
          navigationData.showTaskCreationBox && navigationData.hideLeftSidebar ? 'show' : 'hide'
        } `}
      >
        <div id='task-input' className='task-input'>
          <TopbarInput currentRoute={currentRoute} />
        </div>
      </div>
      <div
        id='top-bar-right-con'
        className={`right-side ${!navigationData.hideLeftSidebar ? 'left-menu-open' : ''}`}
      >
        {renderSearchOption()}
        {renderLevelProgressBar()}
        {renderAdminOption()}
        {renderNotificationOption()}
        {renderLeaderboardOption()}
        <span
          id='profile-icon-con'
          className='top-icon profile-icon'
          onClick={() => openProfileModal()}
          data-place='bottom'
          data-class='tool-tip'
          data-background-color='#3c3c5b'
          data-tooltip-id={`tool-tip-profile`}
          data-tooltip-content={`My Profile`}
        >
          <img id='profile-img' src={getProfileImage()} alt='user' />
        </span>
        {/* @ts-ignore*/}
        <Tooltip className='tool-tip' id={`tool-tip-profile`} />
        {renderSettings()}
      </div>
      <Suspense fallback={null}>
        {showThemesLevelUpModal &&
        getLayoutThemeUrl(navigationData.currentRoute.includes('super-admin'), false) !==
          'clean' ? (
          <LevelUpModal currentTheme={activeTheme} />
        ) : null}
      </Suspense>
      <Suspense fallback={null}>
        {showConfirmationPopup ? (
          <ConfirmationPopup
            title={Strings.leave_workspace_confirm_text}
            noBtnTitle={Strings.cancel}
            yesBtnTitle={Strings.confirm}
            closeModal={() => setConfirmationPopup(false)}
            yesClicked={handleClickLeave}
            isLoading={isLoading}
          />
        ) : null}
      </Suspense>
      <Suspense fallback={null}>
        {showDeleteConfirmationPopup ? (
          <ConfirmationPopup
            title={Strings.delete_workspace_confirm_text}
            noBtnTitle={Strings.cancel}
            yesBtnTitle={Strings.confirm}
            closeModal={() => setDeleteConfirmationPopup(false)}
            yesClicked={handleClickDelete}
            isLoading={isLoading}
            placeholder={Strings.type_delete_to_confirm}
          />
        ) : null}
      </Suspense>
      <Suspense fallback={null}>{showThemesPopupModal ? <ThemesPopup /> : null}</Suspense>
      <Suspense fallback={null}>{showThemeCardModal ? <ThemeCardModal /> : null}</Suspense>
    </div>
  );
}
