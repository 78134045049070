import { StrictMode } from 'preact/compat';
import { render } from 'preact';
// import { router } from 'navigation/routing';
// import { App } from './app'
// import './index.css'
// import ReactDOM from 'react-dom';
import './assets/styles/reset.css';
import './assets/styles/base.css';
import Routing from './navigation/routing';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
// import rg4js from 'raygun4js';
import appConfig from 'config/config';
import * as Sentry from '@sentry/react';

// rg4js('apiKey', appConfig.raygun_key);
// rg4js('enableCrashReporting', true);
// // rg4js('enablePulse', true); disable Raygun real time user tracking

// rg4js('options', {
//   excludedHostnames: ['localhost'],
// });
// // render(<App />, document.getElementById('root') as HTMLElement)

const tagManagerArgs = {
  gtmId: 'GTM-N38VFTK',
};

if (document.location.origin === 'https://app.magictask.io') {
  TagManager.initialize(tagManagerArgs);
  ReactPixel.init('546861916431769');
  ReactPixel.pageView();
  const script = document.createElement('script');
  script.setAttribute('id', 'mcjs');
  script.innerHTML =
    '!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/537628644d59b5ff362c9862f/979718f92418d47e077eb796b.js");';
  document.head.appendChild(script);
}

if (document.location.origin !== 'http://localhost:8000') {
  Sentry.init({
    dsn: 'https://59c4cec6f516b5c47f7ea35d7c03cd51@o4507973966561280.ingest.us.sentry.io/4507999455412224',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', appConfig.apiUrl],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

  render(
    <StrictMode>
      <Routing />
    </StrictMode>,
    document.getElementById('root') as HTMLElement,
  );
