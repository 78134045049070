import { useState, Fragment, TargetedEvent } from 'preact/compat';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAlert } from 'react-alert';

import { subscriptionState } from 'recoil/AccountState';
import { showCancelPlanModalState } from 'recoil/NavigationState';
import { getSubscriptionDetail } from 'recoil/AccountState/update';
import { cancelSubscription as cancelSubscriptionAPI } from 'services/SubscriptionService';

import { Strings } from 'resources';
import Input from 'components/UI/Input';
import SmallButton from 'components/UI/SmallButton';

export default function CancelPlanModal() {
  const alert = useAlert();
  const [textValue, setTextValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const subscriptionDetail = useRecoilValue(subscriptionState);
  const setShowCancelPlanModal = useSetRecoilState(showCancelPlanModalState);

  const onChangeText = (e: TargetedEvent) => {
    (e.target as HTMLInputElement).value === 'CANCEL' ? setIsDisabled(false) : setIsDisabled(true);

    setTextValue((e.target as HTMLInputElement).value);
  };

  const handleConfirmTap = async () => {
    if (subscriptionDetail?.id) {
      setIsLoading(true);
      const { success, data } = await cancelSubscriptionAPI({
        subscriptionId: subscriptionDetail.id,
      });
      if (success) {
        alert.success(data.message);
        await getSubscriptionDetail();
      }
      setIsLoading(false);
      setTextValue('');
      setShowCancelPlanModal(false);
    }
  };

  const renderCancelContainer = () => {
    return (
      <Fragment>
        {renderInputContainer()}
        <div className='btn-con'>
          <SmallButton clicked={() => handleConfirmTap()} loading={isLoading} disabled={isDisabled}>
            {Strings.confirm}
          </SmallButton>
        </div>
      </Fragment>
    );
  };

  const renderInputContainer = () => {
    return (
      <Input
        id='cancel-confirmation-input'
        placeholder={Strings.type_here}
        autoComplete='off'
        data-lpignore='true'
        data-form-type='other'
        onChange={(event) => onChangeText(event)}
        value={textValue}
        secure={false}
      />
    );
  };

  return (
    <Fragment>
      <div className={`cancel-plan-modal-container`}>
        <div className={`cancel-plan-inner-container`}>
          <div className='header-container'>
            <h3>{Strings.cancel_plan}</h3>
            <span onClick={() => setShowCancelPlanModal(false)} className='cross-icon'>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>

          <div className='cancel-plan-content-container'>
            <p className='cp-content-description'>{Strings.cancel_plan_description}</p>

            {renderCancelContainer()}
          </div>
        </div>
      </div>
      <div className='cancel-plan-popup-bg' onClick={() => setShowCancelPlanModal(false)}></div>
    </Fragment>
  );
}
