export default {
  /** Auth */
  username_placeholder: 'Username',
  password: 'Password',
  sign_in: 'Sign In',
  magic: 'Magic',
  task_io: 'Task.io',
  dont_have_account: "Don't have an account?",
  create_new_account: 'Create Account',
  cant_login: "Can't log in?",
  reset_your_password: 'Reset your password',
  join_now: 'Join Now',
  early_access_code: 'Early access code',
  early_access_message: 'Create a new workspace with your team for ',
  free: 'Free',
  agree_to: 'Agree to ',
  terms: 'Terms and Conditions',
  and: ' and ',
  privacy_policy: 'Privacy Policy',
  workspace_name: 'Workspace Name',
  create_first_workspace: 'Create your first workspace',
  email_address: 'Email Address',
  email_address_placeholder: 'Email Address',
  login_success: 'You have logged in successfully!',
  account_success: 'Your account is successfully created!',
  operation_canceled_by_user: 'Operation canceled by the user.',
  beta_plan: 'Beta Plan',
  beta_subHeader: 'Create a new workspace with your team, for free',
  get_started: 'Get Started',
  create_account: 'Create Your Account',
  account_full_name: 'Your full name',
  account_email_placeholder: 'Email Address',
  submit: 'Submit',
  forgot_password: 'Forgot Password',
  forgot_pass_msg: 'Tell us your email address, and we’ll get you back on track in no time ',
  reset: 'Reset',
  current_password: 'Current Password',
  reset_password: 'Reset Password',
  confirm_password: 'Confirm password',
  new_password: 'New password',
  change_password: 'Change Password',

  /** Top Bar */
  logout: 'Logout',
  settings: 'Settings',
  super_admin: 'Super Admin',
  screen_saver: 'Screen Saver',
  submit_feedback: 'Submit Bug/Feedback',
  rename_workspace: 'Rename Workspace',
  update_workspace_image: 'Update Workspace Image',
  leave_workspace: 'Leave Workspace',
  create_workspace: 'Create New Workspace',
  please_enter_workspace_name: 'Please enter workspace name',
  create_task_placeholder: 'Create task [!project @assignee #tag $size %recurrence]',
  create_task_placeholder_for_template: 'Create task [@assignee #tag $size %recurrence]',
  delete_workspace: 'Delete Workspace',

  /** Left Bar */
  team: 'Team',
  add_project: 'Add Project',
  change_log: 'Change Log',
  my_tasks: 'My Tasks',
  rename_folder: 'Rename Folder',
  delete_folder: 'Delete Folder',
  new_project: 'New Project',
  create_folder: 'Create Folder',
  create_template: 'Create template',
  project_templates: 'Project Templates',
  template_name: 'Template name',
  rename_project: 'Rename Project',
  create_new_project_template: 'Create new project template',
  select_folder: 'Select a folder',
  project_folder: 'Project Folder',

  /** Admin */
  workspace_administration: 'Workspace Administration',
  owner: 'Owner',
  admin: 'Admin',
  manager: 'Manager',
  standard: 'Standard',
  guest: 'Guest',
  projects: 'Projects',
  remaining_points: 'Remaining points',
  no_date: 'No start and end date',
  points_remaining: 'Points Remaining',
  status_filter: 'Filter by status',
  manage_user: 'Manage User',
  portfolio: 'Portfolio',
  analytics: 'Analytics',
  start_end_date: 'Start/End Date',
  date: 'Date',
  points: 'Points',
  points_in_focus: 'Points in focus',
  points_completed: 'Points Completed',
  completed_points: 'Completed points',
  in_focus_points: 'In focus points',
  manage_users: 'Manage Users',
  member_invited: 'Member Invited',
  status: 'Status',
  invited_date: 'Invited Date',
  joined_date: 'Joined Date',
  last_login: 'Last Login',
  last_login_email: 'Last Login Email',
  first_login: 'First Login',
  time_logged_in: 'Total Time Logged In',
  role: 'Role',
  active: 'Active',
  show_inactive: 'show inactive only',
  show_archived: 'show archived only',
  edit_role: 'Edit Role',
  no_role: 'No Role',
  invite_user: 'Invite User',
  enter_email: 'Enter Email Address',
  send: 'Send',
  cancel: 'Cancel',
  remove: 'Remove',
  confirm: 'Confirm',
  share: 'Share',

  no_data_to_display: 'No data to display',
  /** Filters */
  yesterday: 'Yesterday',
  filter_by: 'Filter by',
  today: 'Today',
  current_month: 'Current Month',
  last_month: 'Last Month',
  year_date: 'Year to Date',
  previous_year: 'Previous Year',
  custom_date: 'Custom Date',
  all_time: 'All Time',
  type_date: 'Type of Date',
  size: 'Size',
  all_sizes: 'All Sizes',

  /** Settings */
  select_workspace: 'Select Workspace',
  email: 'Email',
  delete_account: 'Delete Account',
  default_size: 'Default Size',
  choose_size: 'Choose your default size',
  notifications: 'Notifications',
  new_member_notif: 'New member added to project',
  project_at_risk: 'Project at-risk or off track',
  assignee_notif: "You've been assigned to task",
  completed_notif: 'Someone completed your task',
  comment_notif: 'Someone commented on your task',
  task_modif_notif: 'Someone modified your task',
  general: 'General',
  spellcheck: 'Spellcheck',
  assignDefaultToSelf: 'Always assign newly created tasks to myself',
  closeCommentOnAdd: 'Close comment box on comment creation/addition',
  animations: 'Animations',
  sound_effects: 'Sound Effects',
  rollup_reports: 'Roll Up Reports',
  completed_tasks: 'Completed Tasks',
  frequency: 'Frequency',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  yearly: 'Yearly',
  completedTasks: 'Completed Tasks',
  recentlyCreatedTasks: 'Recently Created Tasks',
  taskNeedAttention: 'Tasks That Need Attention',
  upcomingDueDate: 'Upcoming Due Dates',
  overdueDueDate: 'Overdue Due Dates',
  pushNotification: 'Push Notifications',
  emailNotification: 'Email Notifications',
  inAppNotifications: 'In-App Notifications',
  slackNotifications: 'Slack Notifications',
  rollUpWorkspaceWarning: 'You must have atleast 1 workspace selected!',
  /** User Profile */
  about: 'About',
  joining_date: 'Creation Date',
  upload_image: 'upload image',
  image_upload_failed: 'Image upload failed!',

  /** Meetings */
  meeting: 'Meeting',
  meetings: 'Meetings',
  ad_hoc_meeting: 'Ad-hoc Meeting',
  scheduled_meeting: 'Scheduled Meeting',
  create: 'Create',
  select_project: 'Select a project',
  project_template: 'Project Template',
  no_project: 'No Project',
  add_invitees: 'Add Invitees',
  ad_hoc_untitled_meeting: 'Ad-hoc or Untitled Meetings',
  hide: 'Hide',
  show: 'Show',
  date_filter: 'Filter by Date',
  project: 'Project',
  attendees: 'Attendees',
  member: 'Member',
  duration: 'Duration',
  meeting_date: 'Meeting Date',
  meeting_number: 'Meeting Number',
  project_name: 'Project Name',
  meeting_link: 'Meeting Link',
  meeting_name: 'Meeting Name',
  copy_link: 'Copy Link',
  create_task: 'Create Task',
  link_copied: 'Link Copied',
  invite_new_user: 'Invite New User',
  member_name: 'Member name',
  in_progress: 'In Progress',
  upcoming: 'Upcoming',
  meeting_concluded: 'Meeting has been concluded',
  joined_meeting: 'joined the meeting',
  disconnected: 'disconnected',
  record: 'Record',
  link_copy_success: 'Link copied successfully!',
  only_participants_can_view: 'Only participants can view meeting details.',

  // Analytics
  tasks_completed: 'Tasks Completed',
  avg_tasks_completed: 'Average Tasks Completed',
  grp_avg_tasks_completed: 'Group Average Tasks Completed',
  burndown_report: 'Burndown Report',
  comments_created: 'Comments Created',
  meetings_created: 'Meetings Created',
  meetings_duration: 'Meetings Duration',
  average: 'Average',
  meetings_task: 'Meetings Task Created',
  attachments_created: 'Attachments Created',
  tasks_created: 'Tasks Created',
  time_period: 'Time Period',
  time_interval: 'Time Interval',
  filter_assignee: 'Filter by Assignee',
  filter_project: 'Filter by Project',
  project_selected: 'Project Selected',
  user_selected: 'User Selected',
  reset_filter: 'Reset Filter',
  assignee: 'Assignee',
  no_data_available: 'No data available for this range!',

  // enter_workspace_name: "Enter Your Workspace Name",
  add_team_members: 'Add Team Members',
  workspace_name_placeholder: 'Workspace name',
  optional: 'Optional',
  add_team: 'Add Team',
  add_teammates: 'Add Teammates',
  personal_information: 'Personal Information',
  full_name_placeholder: 'Full name',
  first_name_placeholder: 'First Name',
  last_name_placeholder: 'Last Name',
  position_placeholder: 'Position in the workspace',
  workspace: 'Workspace',
  photo: 'Photo',
  phone_placeholder: 'Phone #',
  phone: 'Phone',
  teammate_email: 'Teammates email',
  send_invite: 'Send Invite',
  invite: 'Invite',
  invite_new_member: 'Invite new member',
  search_tags: 'Search tags',
  remove_tag_msg:
    'This will remove this tag from all items in this project, are you sure you want to do this?',
  yes: 'Yes',
  no: 'No',
  notification_permission_text_1: 'MagicTask needs your permission to ',
  notification_permission_text_2: 'enable desktop notifications.',
  cant_invite_yourself: 'You can not invite yourself.',

  /** Task List */
  open: 'Open',
  completed: 'Completed',
  filter_date: 'Filter By Date',
  filter_tag: 'Filter By Tag',
  show_hide: 'Show/Hide',

  /** Search */
  search: 'Search',
  looking_for: 'I am looking for..',
  comments: 'Comments',
  tags: 'Tags',
  tasks: 'Tasks',
  recent_search: 'Recent Search',
  search_results_for: 'Search results for',
  search_error: 'Unable to fetch search results',
  no_search_results: 'No search results!',
  empty_search_text: 'Please enter some text to search.',

  /** Task Details */
  created_by: 'Created by',
  completed_by: 'Completed by',
  on: 'on',
  due: 'Due',
  work_start: 'Work Start',
  work_end: 'Work End',
  subtasks: 'Subtasks',
  attachments_only: 'Attachments only',
  comment_here: 'Comment here',
  task: 'Task',
  drop_here: 'Drop here',
  enter_message: 'Please enter a message.',
  comment_failed: 'Could not add comment!',
  comment_success: 'Comment successfully added!',
  delete_comment_confirm_text: 'Are you sure you want to delete this comment?',
  edit_comment_error: 'The comment cannot be left blank during editing.',

  /** Project Info */
  archived: 'Archived',
  archive: 'Archive',
  point_value: 'Point Value',
  points_earned: 'Points Earned',
  members: 'Members',
  add_member: 'Add member',

  describe_feedback: 'Describe your feedback or bug',
  feedback_submitted: 'Feedback submitted.',

  /* create theme */
  theme_name: 'Theme Name',
  thumbnail_image: 'Card/Background Image/Thumbnail Image',
  theme_price_in_usd: 'Price in USD',
  theme_price: 'Price',
  set_quantity: 'Set Quantity',
  quantity: 'Quantity',
  notification_message: 'Notification Message',
  time_of_launch: 'Time of the Launch',
  select_time_of_launch: 'Select Time of the Launch',
  theme_zip: 'Upload Theme Zip',
  upload_theme_zip: 'theme.zip',
  featured_theme: 'Featured Theme',
  browse: 'Browse',
  browse_file: 'Browse File',
  create_new_theme: 'Create New Theme - Card Details',
  enter_name: 'Enter name',
  choose_difficulty: 'Choose Difficulty',
  choose_rarity: 'Choose Rarity',
  enter_cost: 'Enter Cost $',
  enter_quantity: 'Enter Quantity',
  release_date_time: 'Release Date and Time',
  choose_release_date: 'Choose Release Date',
  choose_release_time: 'Choose Release Time',
  difficulty_easy: 'Easy',
  difficulty_medium: 'Medium',
  difficulty_hard: 'Hard',
  difficulty_helldive: 'Helldive',
  rarity_common: 'Common',
  rarity_rare: 'Rare',
  rarity_epic: 'Epic',
  rarity_legendary: 'Legendary',
  rarity_majestic: 'Majestic',
  rarity_grandmaster: 'Grandmaster',
  upload_theme_files: 'Upload Preview Theme Files',
  web_preview: 'Web Preview',
  mobile_preview: 'Mobile Preview',
  continue_arrow: 'Continue >',
  enter_points: 'Enter Points',
  trigger: 'Trigger',
  choose_trigger: 'Choose Trigger',
  description: 'Description',
  enter_description: 'Enter Description',
  remove_level: 'Remove Level',
  create_level_builder: 'Create New Theme - Level Builder',
  selected_trigger: 'Selected Trigger',

  /* Super Admin */
  manage_workspaces: 'Manage Workspaces',
  workspaces: 'Workspaces',
  creation_date: 'Creation Date',
  total_team_members: 'Total Team Members',
  total_tasks_created: 'Total Tasks Created',
  total_projects_created: 'Total Projects Created',
  total_completed_points: 'Total Completed Points',
  tutorial_progress: 'Tutorial Progress',
  owner_email: 'Owner Email',
  reset_pass_succes: 'Email sent to reset password',
  total_tasks_completed: 'Total Tasks Completed',
  whats_new: 'Whats New',
  create_theme: 'Create Theme',
  add_feature: 'Add Feature +',
  remove_feature: 'Remove Feature -',
  remove_section: 'Remove Section -',
  add_section: 'Add Section +',
  preview: 'Preview',
  most_recent_features: 'MOST RECENT FEATURES',
  got_it: 'Got it',
  follow_for_updates: 'Follow for more updates!',
  themes: 'Themes',
  create_theme_plus: '+ Create Theme',
  save: 'Save',
  go_back_with_icon: '< Go Back',
  join_community: 'Join our community',
  export: 'Export',

  /** Theme listing */
  name: 'Name',
  difficulty: 'Difficulty',
  rarity: 'Rarity',
  levels: 'Levels',
  cost: 'Cost',
  release_date: 'Release Date',
  level: 'Level ',

  /** MarketPlace */
  coming_soon: 'Coming Soon!',
  explore: 'Explore',
  discover_collect_sell: 'Discover, collect, and sell extraordinary Themes',
  worlds_largest_marketplace:
    "on the world's only project management system where you can buy, sell and collect themes",
  exclusive_magictask_drops: 'Exclusive Magic Task drops',
  how_it_works: 'How it works',
  step1: 'Step 01',
  step1_desc:
    'Purchase and collect new exclusive themes as they become available from the MagicTask Marketplace.',
  step2: 'Step 02',
  step2_desc: 'Enable your theme in MagicTask to apply points to it.',
  step3: 'Step 03',
  step3_desc:
    'As your themes gains points it will level up.  This will unlock features such as animations, sound effects, and sound tracks.  This will increase the rarity of your theme.',
  step4: 'Step 04',
  step4_desc: 'Sell/Trade your leveled up theme or hoard them for yourself!',
  price: 'Price',
  on_auction: 'On Auction',
  new: 'New',
  has_offers: 'Has Offers',
  apply: 'Apply',
  to: 'to',
  min: 'Min',
  max: 'Max',
  load_more: 'Load More',
  no_results: 'No Results!',
  loading: 'Loading...',
  results: 'results',
  result: 'result',

  /** Create Folder */
  create_new_folder: 'Create new folder',
  folder_name: 'Folder name',

  /** User Tutorial */
  user_tutorial: 'User Tutorial',
  close_tutorial: 'Close Tutorial',
  go_back: 'Go Back',
  got_it_continue: 'Got It Continue',

  /** delete/leave confirmation texts */
  are_you_sure: 'Are you sure?',
  leave_workspace_confirm_text: 'Are you sure you wish to leave this workspace?',
  delete_folder_confirm_text: 'Are you sure you want to delete the folder?',
  delete_workspace_confirm_text: 'Are you sure you wish to delete this workspace?',
  type_delete_to_confirm: 'Type DELETE to confirm.',
  type_delete_for_confirmation: 'Please type DELETE to confirm deletion of workspace.',

  /** Marketplaces/Themes/Level bar */
  my_themes: 'My Themes',
  marketplace: 'Marketplace',
  total_quantity: 'Total Quantity',
  remaining_quantity: 'Remaining Quantity',
  theme: 'Theme',
  remaining: 'remaining',
  congratulations: 'Congratulations',
  you_have_reached: 'You have Reached',
  flip_card: 'Flip card',
  owned: 'Owned',
  sold_out: 'Sold Out',
  first_equipped: 'First Equipped',
  purchased_on: 'Purchased On',
  of_owners: 'of owners',
  equip: 'Equip',
  close: 'Close',
  pay_now: 'Pay Now',
  processing_payment: 'We are processing the payment...',
  purchase_complete: 'Purchase complete!',
  continue: 'Continue',
  oops: 'Oops!',
  something_went_wrong: 'Something went wrong',
  working_to_resolve: 'We are working to get this resolved. Be back in a jiff!',
  back_to_magictask: 'Back to MagicTask',
  order_confirm_msg: 'You will receive an order confirmation email shortly.',
  order_confirm: 'Order confirmation',
  recurring_tasks: 'Recurring Tasks',
  limited_message: 'You must purchase at least three themes to buy MCARS theme.',
  limited_header: 'Prerequisite',

  /** templates listing */
  created: 'Created',
  no_of_tasks: '# of tasks',
  no_of_points: '# of points',
  no_of_members: '# of members',
  no_of_projects_created: '# of projects created',
  create_new_template: 'Create new template',
  delete: 'delete',

  /** Tutorials */

  ready_to_compete: 'Ready to compete?',
  leaderboard_description:
    'The global leaderboard is a place to see where you rank among the top players in MagicTask.',
  enter_name_heading: 'Enter your display name to begin:',
  enter_display_name: 'Enter display name',
  display_pic: 'Display picture',
  leaderboard_profile_image: 'Provide your profile image for the leaderboard:',
  enrollment_success: 'You are ready to compete in leaderboard',
  enrollment_failure: 'Enrollment is not successfull!!',
  global_leaderboard: 'Global Leaderboard',
  individuals: 'Individuals',
  rank: 'Rank #',
  total_points: 'Total Points',
  themes_owned: 'Themes Owned',
  themes_maxed: 'Themes Maxed',
  date_joined: 'Date Joined',
  theme_max_msg: 'You must have 1 theme to compete',
  theme_buy_msg: 'You currently have no themes in your inventory.',
  theme_buy: 'Click BUY NOW to get one now!',
  buy_now: 'BUY NOW',
  redeem_now: 'Redeem Now',

  /** Personal Info */
  accept: 'Accept',
  reject: 'Reject',
  join_workspace: 'You have successfully joined the workspace',
  error_workspace: 'The invite you used is not valid for this account!!',
  ok: 'OK',

  /** Account Deletion */
  account_deletion: 'Confirm Account Deletion',
  account_deletion_description1:
    'There are other users in below mentioned workspace(s) you must assign a new owner to them to proceed with account deletion.',
  account_deletion_description2:
    'Are you sure you want to delete your account? This will permanently remove all of the data associated with your account.',
  delete_text: ' and press submit to delete your account.',
  enter_here: 'Enter here',
  user_management: 'Go to User Management',
  type: 'Type',
  ownership_transfer: 'Are you sure you want to transfer owernship of',
  ownership_transfer1:
    'There can only be 1 owner per workspace so you will lose all access to control of the workspace and your account will become an Admin by default',
  type_transfer: 'to proceed with owernship transfer.',
  proceed: 'Proceed',
  confirm_ownership_transfer: 'Confirm Ownership Transfer',

  /** Notifications */
  unread: 'Unread',
  all: 'All',
  markAllRead: 'Mark all as read',
  noUnreadNotif: 'No unread notifications 🔥',
  noNotif: 'No notifications 🔥',
  getBack: 'Get back to it!',
  markReadFailed: 'Marking notification as Read failed, Try again',

  // Analytics
  filter_by_device: 'Filter By Device',
  platform_selected: 'Platform Selected',
  android: 'Android',
  ios: 'iOS',
  web: 'Web',
  selected_date: 'Selected Date',

  // Subscription
  feature: 'Feature',
  free_forever: 'Free Forever',
  per_month: '$5 per user / month',
  pleb: 'Pleb',
  elite: 'Elite',
  select_plan: 'Select Plan',
  current_plan: 'Current Plan',
  cancel_plan: 'Cancel Plan',
  upgrade_plan: 'Upgrade Plan',
  table_title: 'Get Started with',
  table_highlight_title: 'Our Plans',
  table_description:
    'Manage basic tasks with core features, perfect for individuals or small teams just starting out.',
  learn: 'Learn More',
  upgrade: 'Upgrade',
  subscription: 'Subscription',
  check_plans: 'Check Plans',
  billing_history_title: 'Billing History',
  subscription_start_date: 'Subscription Start Date',
  subscription_end_date: 'End Date',
  subscription_invoice_date: 'Next Invoice Date',
  add_new_card: 'Add New Card Details',
  go_to_dashboard: 'Go to Dashboard',
  retry_subscription: 'Retry Subscription',
  cancel_plan_description: 'To cancel the plan type “CANCEL” below and click Confirm.',
  cancel_text: 'CANCEL',
  type_here: 'Type here',
};
